import Layout from "../../components/layout"
import Heading from "../../components/heading"
import HorizontalImage from "../../components/horizontal-image"
import Text from "../../components/text"
import VerticalImage from "../../components/vertical-image"
import VerticalText from "../../components/vertical-text"
import React from 'react';
import { graphql } from "gatsby"

export const query = graphql`
  query {
    photo1: file(relativePath: { eq: "byt-muchova/kr-1.jpg" }) { ...fluidImage }
    photo2: file(relativePath: { eq: "byt-muchova/kr-2.jpg" }) { ...fluidImage }
    photo3: file(relativePath: { eq: "byt-muchova/kr-3.jpg" }) { ...fluidImage }
    photo4: file(relativePath: { eq: "byt-muchova/kr-4.jpg" }) { ...fluidImage }
    photo5: file(relativePath: { eq: "byt-muchova/kr-5.jpg" }) { ...fluidImage }
  }
`
const BytMuchovaPage = ({ data }) => (
  <Layout title="Byt Muchova">
    <HorizontalImage marginTop borderBottom image={data.photo1.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo2.childImageSharp.fluid} />
    <VerticalText>
      <Heading>Podkrovní byt Muchova, Praha 6, 2014</Heading>
      <Text>Studie přestavby půdního ateliéru na podkrovní byt 1+kk. Zajimavým prvkem podkroví je secesní prosklení a asymetrická střecha. Stávající ateliér byl zaklopen SDK podhledem a opatřen izolací. Studie počítá s novým zateplením střešní roviny nad celou půdou, tím by bylo umožněno otevření prostoru bytu až do hřebenu. Byt je navržen pro mladou ženu, která pracovně cestuje po světě. Do otevřeného prostoru je vložen nový objem multifunkčního bloku. Jeho součástí je kuchyňská linka, šatní skříně, schodiště do patra a koupelna. Prosklení koupelny je řešeno stěnou z mléčného skla umístěnou za kuchyňskou linkou. V patře je navržena postel s úložným prostorem a pracovní stůl, který navíc plní funkci zábradlí okolo schodiště. Barevnost bytu je laděna do přírodních tónů s výraznými barevnými doplňky.</Text>
    </VerticalText>
    <HorizontalImage borderBottom borderTop image={data.photo3.childImageSharp.fluid} />
    <HorizontalImage borderBottom image={data.photo4.childImageSharp.fluid} />
    <HorizontalImage image={data.photo5.childImageSharp.fluid} />
  </Layout>
)

export default BytMuchovaPage
